import React from 'react';
import {Nav, Navbar, Container } from 'react-bootstrap';


const Header = () =>{
return(
      <Navbar bg="dark" variant='dark'>
        <Container>
        
          <Navbar.Brand href="#home">ProShop</Navbar.Brand>

          <Nav className="ml-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/cart"><i className='fas fa-shopping-cart'></i>Cart</Nav.Link>
            <Nav.Link href="/login"><i className='fas fa-user'></i>Sign In</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
)

}

export default Header;